$slider-nav--color: $color-white;
$slider-nav--color-hover: $color-celeste;
$slider-nav--color-active: orange;
$slider-nav--bg: transparent;
$slider-nav--bg-hover: transparent;
$slider-nav--bg-active: transparent;

// $slider-pagination--color: $color-white;
// $slider-pagination--color-hover: $color-white;
// $slider-pagination--color-active: $color-white;
// $slider-pagination--bg: transparent;
// $slider-pagination--bg-hover: $color-black;
// $slider-pagination--bg-active: $color-white;

// $pagination-margin: 40px;
// $pagination-w: 50px;
// $pagination-r: 50px;

$contactForm-bg: #404041;
$menu-offset-padding: 80px;

.body--slider-main {
	height: 100%;
	overflow: hidden;
	background-color: $color-black;

	.l-navigation-panel__item--logo {
		&.is-static {
			cursor: default;
		}
	}

	.l-layout-wrapper {
		padding-top: 0;
		min-height: 100%;

		.l-page {
			padding-left: 80px;
			padding-right: 0;
		}
	}

	.l-page__inner {
		width: 100%;
	}

	.l-page__nav {
		display: none;
	}

	.b-preloader-main {
		position: fixed;
	}

	.l-slider-item__container {
		// учет меню для центрирования контента
		padding-right: $menu-offset-padding;

		@include breakpoint(sm) {
			padding-right: 0;
		}
	}

	.b-slider-wrapper--main.preloader {
		&:after {
			background-color: $color-black;
		}
	}

	.b-slider-item {
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
	}

	// логика анимации "проявления" в саб-слайдере кейсов -----
	.b-slider-item--projects.swiper-slide-active .swiper-slide.swiper-slide-active {
		.b-project-list-item__link-wrapper {
			opacity: 1;
		}

		@for $i from 1 through 9 {
			.b-project-list-item__link-wrapper:nth-child(#{$i}) {
				transition-delay: 0.5s + 0.1s * $i;
			}
		}
	}

	.js-sub-slider-wrapper {
		.swiper-slide {
			.b-project-list-item__link-wrapper {
				opacity: 0;
			}
		}

		.b-project-list-item__link-wrapper {
			transition: opacity .6s ease;
		}
	}
	// логика анимации "проявления" в саб-слайдере кейсов -----

	// логика анимации "проявления" слайда с формой -----
	.b-slider-item--form {
		.l-slider-item__inner {
			&:before {
				content: '';
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				background-color: $contactForm-bg;
				z-index: -1;

				transform: translateY(100%);
				transition: transform .8s ease .4s;
			}
		}

		&.swiper-slide-active {
			.l-slider-item__inner:before {
				transform: translateY(0);
				// animation: sliderBgForm .8s ease .4s forwards;
			}

			.b-form__link {
				opacity: 1;
				transform: translateY(0);
				transition: transform .8s ease .8s, opacity .6s ease .8s;
			}

			.b-form__fields {
				opacity: 1;
				transform: translateY(0);
				transition: transform .8s ease 1.2s, opacity .6s ease 1.2s;
			}
		}

		.b-form {
			padding-left: 4vw;
			padding-right: 4vw;

			&__title {
				display: none;
			}
		}

		.b-slide-title {
			padding-left: 4vw;
			padding-right: 4vw;
		}

		.b-form__link,
		.b-form__fields {
			opacity: 0;
			// transform: translateY(10vh);
			transform: translateY(200px);
		}
	}

	.browser-ie & {
		.b-slider-item--form {
			.l-slider-item__inner:before {
				transform: none;
				opacity: 0;
				transition: opacity .8s ease .4s;
			}

			&.swiper-slide-active {
				.l-slider-item__inner:before {
					opacity: 1;
				}
			}
		}
	}
	// логика анимации "проявления" слайда с формой -----

	// логика анимации "проявления" слайда с контактами -----
	.b-slider-item--contacts {
		background-color: $contactForm-bg;

		// .b-slider-item__content,
		// .contacts__row,
		// .contacts__MAP {
		// 	height: 100%;
		// }

		.contacts__row {
			min-height: 60vh;
		}

		.contacts__MAP {
			height: 100%;

			&.preloader:after {
				background-color: rgba(64, 64, 65, .7);
			}
		}

		@include breakpoint(sm) {
			.b-slider-item__content,
			.contacts__row {
				height: 100%;
			}

			.contacts__MAP {
				width: 100vw;
				position: relative;
				left: 50%;
				transform: translateX(-50%);

				// grid padding
				// margin-left: 30px;
			}

			.contacts__col {
				height: 50%;
				padding-left: 0;
				padding-right: 0;
			}
		}

		@include breakpoint(xs) {
			.contacts__MAP {
				margin: 0;
			}
		}
	}

	.b-slider-item--0 {
		.b-slider-item__content {
			position: relative;

			// IE
			display: flex;
			flex-direction: column;
		}
	}

	.b-slider-item--1 {
		&:before {
			content: '';
			position: absolute;
			width: 150vw;
			height: 150vh;
			right: 0;
			top: 0;
			background-color: $color-black;
			transform: rotate(-8deg);
			z-index: -1;
			transform-origin: right top;
		}
	}

	.b-slider-item--2 {
		&:before {
			content: '';
			position: absolute;
			background-color: $color-black;
			width: 150vw;
			height: 100vh;
			left: -20vw;
			bottom: 80vh;
			background-color: #000000;
			transform: rotate(-8deg);
			z-index: -1;
			transform-origin: right bottom;
		}
	}

	// -------- content ----- begin --
	// -- блок ссылок с иконкой ✔️
	.b-preloader-icons {
		margin: auto;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-content: space-between;

		// new
		min-height: 20vw;
		// нид задать высоту для IE

		&__circle {
			flex: 0 1 50%;
			max-width: 50%;
			display: flex;
			align-items: center;

			position: relative;

			&:nth-child(odd) {
				.b-preloader-icons__content {
					text-align: right;
				}

				flex-direction: row-reverse;

				h3,
				p {
					transform: translateX(-50px);
				}
			}

			&:nth-child(even) {
				.b-preloader-icons__content {
					text-align: left;
				}

				h3,
				p {
					transform: translateX(50px);
				}
			}

			@for $i from 1 through 4 {
				&--#{$i}:before {
					background-image: url('/images/icons/slide-0_icon-#{$i}.svg');
				}
			}

			&:before {
				content: '';
				display: block;
				width: 8vw;
				height: 8vw;
				flex-shrink: 0;
				background-repeat: no-repeat;
				background-position: center center;
				border-radius: 100%;
				border: 1px solid $color-white;
				margin: 0 2vw;
				// animation
				transition: transform .4s ease;
				transform: scale(0);
			}

			// &:hover:before {
			// 	transform: scale3d(1, 1, 1.01);
			// }

			cursor: pointer;

			// поведение ссылок главной
			// (заглушен visited, легка прозрачность)
			@extend .link-mainPAge-type;
		}

		&__content {
			white-space: nowrap;
			font-size: 20px;

			h3 {
				margin-bottom: .5em;
				font-size: 2em;
				transition: transform .8s ease .4s, opacity .6s ease .4s;
			}

			p {
				font-size: 1em;
				transition: transform .8s ease .5s, opacity .6s ease .6s;
			}

			@media screen and (max-width: 1440px) {
				font-size: 16px;
			}

			@include breakpoint(lg) {
				font-size: 14px;
			}

			@include breakpoint(md) {
				font-size: 12px;
			}
		}

		h3,
		p {
			opacity: 0;
		}

		// animation
		// &.is-hide-icon {
		// 	.b-preloader-icons__circle:before {
		// 		transform: scale(0);
		// 	}

		// 	h3,
		// 	p {
		// 		opacity: 0;
		// 	}

		// 	.b-preloader-icons__circle:nth-child(odd) {
		// 		h3,
		// 		p {
		// 			transform: translateX(-50px);
		// 		}
		// 	}

		// 	.b-preloader-icons__circle:nth-child(even) {
		// 		h3,
		// 		p {
		// 			transform: translateX(50px);
		// 		}
		// 	}
		// }
	}

	.js-is-animated .b-preloader-icons {
		.b-preloader-icons__circle {
			&:before {
				transform: scale3d(0.9, 0.9, 0.9);
			}
			&:hover:before {
				transform: scale3d(1, 1, 1.01);
			}
		}

		h3,
		p {
			opacity: 1;
		}

		.b-preloader-icons__circle:nth-child(odd) {
			h3,
			p {
				transform: translateX(0px);
			}
		}

		.b-preloader-icons__circle:nth-child(even) {
			h3,
			p {
				transform: translateX(0px);
			}
		}
	}
	// ----

	// подсказка мышка ✔️
	.mouse {
		opacity: 0;
		transition: opacity .9s;
		width: 34px;
		position: absolute;
		// left: 50%;
		// центрирование с учетом отступа
		left: calc(50% - #{$menu-offset-padding / 2});
		top: 96%;
		transform: translate(-50%, -100%);

		&:hover {
			opacity: 1;

			.help-mouse-wheel {
				&:before, &:after {
					animation: indicator-up-down 1.5s infinite ease-in-out;
					height: 8px;
					opacity: 0;
				}
			}

			.help-down {
				animation: arrow-down .3s infinite alternate;
			}
		}

	}

	.help-mouse-down {
		border: 2px solid #FFF;
		border-radius: 17px;
		height: 50px;
		margin: 15px auto 10px;
		position: relative;
		width: 30px;
	}

	.help-mouse-wheel {
		display: block;
		position: relative;
		left: 50%;
		transition: opacity .5s;

		&:before, &:after {
			content: '';
			background: #FFF;
			border-radius: 50%;
			margin-left: -2px;
			height: 4px;
			position: absolute;
			top: 5px;
			transition: top .5s, height .5s, opacity .5s;
			width: 4px;
		}

		&:before {
			animation: scroll 1.5s .75s infinite ease-in-out;
		}

		&:after {
			animation: scroll 1.5s infinite ease-in-out;
		}
	}

	.help-down {
		animation: arrow-down .6s infinite alternate ease-in-out;
		border-bottom: 2px solid #cdcdcd;
		border-right: 2px solid #cdcdcd;
		display: block;
		height: 5px;
		margin: 0 auto;
		transform: rotate(45deg);
		transition: opacity .5s;
		width: 5px;
	}
	// --------

	.b-slider-item__content {
		color: $color-white;
		// padding: 20px #{$pagination-w + $pagination-r} 20px 4vw;
		padding: 20px 4vw;

		*:last-child {
			margin-bottom: 0;
		}
	}

	.b-slide-title {
		// font-weight: 600;
		// @include font-size(70px);
		// line-height: 1.3;
		// margin-bottom: 0.5em;

		// @include rem(line-height, 88px);
		@extend .header-main;

		&_h3 {
			font-weight: 600;
			@include font-size(32px);

			@include breakpoint(xs) {
				@include font-size(20px);
			}
		}

		&.b-slide-title--small {
			@include font-size(60px);

			@media screen and (max-width: 1440px) {
				@include font-size(40px);
			}

			@include breakpoint(xs) {
				@include font-size(30px);
			}
		}

		&.b-slide-title--big {
			@include font-size(90px);

			@media screen and (max-width: 1440px) {
				@include font-size(60px);
			}

			@include breakpoint(sm) {
				@include font-size(50px);
			}

			@include breakpoint(xs) {
				@include font-size(35px);
			}
		}
	}

	.b-slide-key {
		@include rem(margin-bottom, 90px);

		.b-indicator {
			// font-size: 1rem;
			font-size: 20px;
			@include rem(margin-bottom, 35px);

			&__value {
				display: inline-block;
				color: #fff;
				font-size: 3.8em;
				@include font(700);
			}

			&__unit {
				display: inline-block;
				color: #fff;
				font-size: 2em;
				@include font(700);

				&:first-child {
					margin-top: 0.27em;
				}
			}

			&__text {
				display: block;
				color: #fff;
				font-size: 1em;
				line-height: 1.1;
				@include font(450);
			}

			@media screen and (max-width: 1400px) {
				font-size: 16px;
			}

			@include breakpoint(md) {
				font-size: 12px;
			}
		}

		.row {
			&:first-child {
				opacity: 0;
				transform: translateY(-10%);
				// transition: opacity 0.9s linear, transform 0.9s ease;
				// transition-delay: 1s;
			}

			&:last-child {
				opacity: 0;
				transform: translateY(-10%);
				// transition: opacity 0.9s linear, transform 0.9s ease;
				// transition-delay: 1.3s;
			}

			& + .row {
				margin-top: 10vh;
			}
		}

		@media screen and (max-width: 1600px) {
			@include rem(margin-bottom, 50px);
		}

		@include breakpoint(sm) {
			margin-bottom: 0;
		}
	}

	.js-is-animated .b-slide-key {
		.row {
			&:first-child {
				opacity: 1;
				transform: translateY(0%);
				transition: opacity 0.9s linear, transform 0.9s ease;
				// transition-delay: 1s;
				transition-delay: .7s;
			}

			&:last-child {
				opacity: 1;
				transform: translateY(0%);
				transition: opacity 0.9s linear, transform 0.9s ease;
				// transition-delay: 1.3s;
				transition-delay: 1s;
			}
		}
	}

	.b-slide-info {
		opacity: 0;
		transform: translateY(-10%);
	}

	.js-is-animated .b-slide-info {
		opacity: 1;
		transform: translateY(0%);
		transition: opacity 0.9s linear, transform 0.9s ease;
		transition-delay: 2.5s;
	}

	.b-slide-products {
		margin-bottom: 35px;
		ul {
			margin: 0;

			li:before {
				width: 10px;
				height: 10px;
				top: 0.3em;
				background-color: rgba(#FFFFFF, 0.5);
			}
		}
	}

	.b-slide-formats {
		display: flex;

		&_item {
			display: flex;
		}

		.b-slide-formats_item + .b-slide-formats_item{
			margin-left: 48px;
		}

		&_icon {
			margin-right: 10px;
		}

		@include breakpoint(xs) {
			flex-direction: column;

			.b-slide-formats_item + .b-slide-formats_item{
				margin-left: 0;
				margin-top: 10px;
			}
		}
	}

	.b-circle-link {
		transition: transform .4s ease, opacity .4s ease;
		opacity: 0;
	}

	.js-is-animated .b-circle-link {
		opacity: 1;
	}

	.b-slider-item--3 {
		.b-slide-title {
			margin-bottom: 1em;
		}
	}

	.col-circle-link {
		align-items: center;
		justify-content: center;
	}
	// -------- content ----- end --

	// .l-slider-button-wrapper {
	// 	position: absolute;
	// 	width: 100%;
	// 	height: 0;
	// 	left: 0;
	// 	right: 0;
	// 	top: 50%;
	// 	transform: translateY(-50%);
	// 	z-index: 1;
	// 	max-width: $site-width;
	// 	margin: auto;
	// }

	.b-slider-bg {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;

		background-color: #231f20;

		&:before {
			content: '';
			position: absolute;
			width: 100vw;
			height: 100vh;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			background-image: url('/images/components/slider/images/bg-overlay-part.png');
			background-size: 3px 3px;
			//
			// transition: background-color .4s ease;
		}

		// &:after {
		// 	content: '';
		// 	position: absolute;
		// 	width: 100%;
		// 	height: 100%;
		// 	right: 0;
		// 	top: 0;
		// 	background-image: url('/images/components/slider/images/splash-bg.jpg');
		// 	background-size: cover;

		// 	opacity: 0;
		// 	transition: opacity .4s ease;
		// }

		&__svg {
			background-color: rgba(0, 0, 0, 0);
			transition: background .4s ease;
			min-height: 100%;
		}
	}

	&[data-helper="1"],
	&[data-helper="2"] {
		.b-slider-bg {
			&__svg {
				background-color: rgba(0, 0, 0, 0.5);
			}
		}
	}

	// &[data-helper="2"],
	// &[data-helper="3"] {
	// 	.b-slider-bg {
	// 		&:after {
	// 			opacity: .5;
	// 		}
	// 	}
	// }

	// ------ subslider ---- begin--
	.js-sub-slider-wrapper {
		.swiper-slide {
			// основной размер раздела
			height: 60vh;
			max-height: 540px;

			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-content: space-between;

			// или первый потомок
			.b-project-list-item__link-wrapper {
				position: relative;
				flex: 0 1 33.33%;
				max-width: 30%;
				max-height: 45%;
			}

			@include breakpoint(md) {
				height: 50vh;
			}

			@include breakpoint(xs) {
				.b-project-list-item__link-wrapper {
					flex-basis: 50%;
					max-width: 50%;
					max-height: 30%;
				}
			}
		}
	}
	// ------ subslider ---- end--
	// ------ news ---- begin--
	.news {
		padding-top: 32px;

		.news-main {
			// основной размер раздела
			min-height: auto;
			height: 60vh;
			max-height: 480px;

			&__text {
				@include font-size(32px);
				width: 70%;
			}

			@include breakpoint(md) {
				height: 50vh;
			}
		}

		.news-block__text {
			@include font-size(22px);
		}

		&__card-wrapper {
			flex: 1 1 50%;

			&:first-child {
				margin-bottom: 20px;
			}
		}

		&__card-wrapper--with-link {
			position: relative;
		}

		&__link-see-more {
			position: absolute;
			left: 0;
			bottom: 100%;
			margin-bottom: 10px;
			@include font-size(24px);
			// text-decoration: underline !important;

			@extend .link-mainPAge-type;

			// стрелка и заглушка состояний
			&:after {
				color: $color-white !important;
			}
		}
	}
	// ------ news ---- end--

	// ------ contacts ---- begin--
	.b-slider-item--4 {
		// .l-slider-item__inner {
		// 	max-width: 100%;
		// }

		// .contacts-row {
		// 	padding-left: 4vw;
		// 	padding-right: calc(4vw + 80px);
		// }

		.contacts-col {
			// padding-top: 10vh;
			padding-top: 2vh;
		}

		.contacts-col--form {
			position: relative;

			&:before {
				content: '';
				position: absolute;
				width: 60vw;
				height: 200vh;
				background-color: $color-black;
				left: 0;
				top: -50%;
				z-index: -1;

				transition: transform .4s ease;
				transform: translateX(110%);
			}
		}

		// &.js-is-animated {
		&.swiper-slide-active {
			.contacts-col--form:before {
				transform: translateX(0%);
			}
		}

	}

	.contacts-col--info,
	.b-form--hire-us {
		@extend .animate-effect__slire-up-to-down;
	}

	.js-is-animated {
		.contacts-col--info {
			transition-delay: .4s !important;
		}

		.b-form--hire-us {
			transition-delay: .6s !important;
		}
	}
	// ------ contacts ---- end--

	// ------ responsive ---
	@media screen and (max-width: 1440px) {
		.b-slide-title {
			@include font-size(50px);
		}

		.contacts-col {
			padding-top: 2vh;
		}

		.b-form__row--footer {
			display: flex;

			.b-form__field-input--submit {
				flex: 1 0 auto;
			}

			.b-form__field-input--policy {
				align-self: flex-start;
			}
		}
	}

	@media screen and (max-width: 1024px) {
		.b-preloader-icons {
			&__circle {
				&:before {
					width: 90px;
					height: 90px;
				}
			}
		}
	}

	@media screen and (max-width: 1024px) and (min-width: 769px) {
		.b-preloader-icons {
			&__circle {
				&:before {
					background-size: 45px;
				}
			}
		}
	}

	@include breakpoint(md) {
		.news {
			.news-block__date {
				font-size: 15px;
			}

			.news-block__text {
				font-size: 18px;
			}

			// .news-main__text {
			// 	font-size: 20px;
			// }
		}

		.b-slider-wrapper--main .l-slider-pagination-wrapper {
			right: 20px;
		}

		.b-slider-item__content {
			padding-right: 10px;
		}
	}

	@include breakpoint(sm) {
		// new mobile
		.l-layout-wrapper .l-page {
			padding-left: 0px;
		}

		.b-slider-wrapper {
			margin-bottom: 0;
		}

		// .b-preloader-icons {
		// 	flex-direction: column;
		// 	align-content: center;

		// 	&__circle {
		// 		flex-basis: 100%;
		// 		max-width: 100%;

		// 		&:not(:last-child) {
		// 			margin-bottom: 4vh;
		// 		}

		// 		&:before {
		// 			margin: 0 4vw;
		// 			background-size: 50%;
		// 		}
		// 	}
		// }

		// .b-slider-bg__svg {
		// 	height: 100%;
		// }

		// .b-slider-projects__wrapper,
		// .b-slide-key {
		// 	.col-circle-link {
		// 		order: -1;
		// 		margin-bottom: 20px;
		// 	}
		// }

		// // .b-slider-projects__link {
		// // 	@include font-size(16px);
		// // }

		// .l-slider-pagination-wrapper {
		// 	right: 20px;
		// 	//
		// 	justify-content: flex-start;
		// }

		// // pagination
		// .b-slider-pagination {
		// 	display: none;
		// }

		// .b-slider-pagination-progress-bar {
		// 	position: static;
		// 	background-color: transparent;
		// 	transform: none !important;
		// }

		// .b-slider-pagination-indicator {
		// 	@include rem(margin-bottom, 20px);
		// 	@include rem(margin-top, 20px);
		// }

		// .b-slide-title {
		// 	// @include font-size(70px);
		// 	// @include rem(line-height, 78px);
		// }

		// new mobile
		.mouse {
			display: none !important;
		}

		overflow-y: auto;

		.swiper-wrapper {
			flex-direction: column;
		}

		.b-slider {
			overflow-y: auto;

			&-item {
				height: auto;
				// min-height: 100vh;
				padding-top: 5vh;
				padding-bottom: 5vh;

				&__content {
					padding-left: 10px;
				}
			}

			&-item--0 {
				padding-top: calc(5vh + 60px);
			}

			&-item--4 {
				background-color: $color-black;

				.contacts-col {
					padding-top: 2vh;
				}
			}
		}

		.news__col-first {
			margin-bottom: 30px;
		}

		.b-slider-bg {
			position: fixed;
			width: 100vw;
			height: 100vh;
			z-index: -1;

			&__svg {
				height: 100%;
			}
		}

		.b-preloader-icons {
			width: 100%;
			flex-direction: column;
			align-content: center;

			&:before {
				margin-left: 0;
			}

			&__content {
				@include font-size(18px);
				white-space: normal;
			}

			&__circle:nth-child(odd) {
				flex-direction: row;

				.b-preloader-icons__content {
					text-align: left;
				}
			}

			&__circle {
				max-width: 100%;
				margin-bottom: 30px;
				align-items: flex-start;

				&:before {
					margin-right: 50px;
					width: 100px;
					height: 100px
				}
			}
		}

		.news {
			.news-block {
				min-height: 200px;
			}

			&__card-wrapper--right-side {
				.news__link-see-more {
					position: static;
				}

				.news-main {
					margin-top: 10px;
					min-height: 200px;
				}
			}
		}
	}

	@include breakpoint(xs) {
		.b-preloader-icons {
			&__content {
				@include font-size(12px);
			}

			&__circle {
				margin-bottom: 20px;

				&:before {
					margin-right: 20px;
					width: 50px;
					height: 50px;
					background-size: 45%;
				}
			}
		}

		.news__col-first {
			margin-bottom: 20px;
		}

		.b-slide-title {
			@include font-size(40px);
			// @include rem(line-height, 48px);
		}

		.b-slider-item__content {
			padding-right: 4vw;
		}

		.col-circle-link {
			margin-top: 2rem;
		}

		.news__card-wrapper {
			// max-height: 120px;
			// margin-bottom: 10px !important;
		}

		.news {
			.news-main {
				max-height: 140px;
			}

			.news-block {
				// min-height: auto;
			}

			.news-block__date {
				font-size: 12px;
				margin-bottom: 10px;
			}

			.news-block__text {
				font-size: 16px;
			}

			.news-main__date {
				margin-top: 20px;
				padding-top: 4%;
				font-size: 14px;
			}

			.news-main__text {
				padding-top: 20px;
				font-size: 16px;
			}
		}

		.b-slider-item--2 .b-slide-title {
			margin-bottom: 40px;
		}
	}
}

@keyframes arrow-down {
	0% {
		margin-top: 5px;
	}

	100% {
		margin-top: 15px;
	}
}

@keyframes indicator-up-down {
	0% {
		opacity: 0;
	}

	50% {
		opacity: 1;
	}

	100% {
		top: 20px;
		opacity: 0;
	}
}

@keyframes scroll {
	0% {
		top: 5px;
		opacity: .3;
	}

	50% {
		opacity: 1;
	}

	100% {
		top: 15px;
		opacity: 0;
	}
}

@keyframes sliderBgForm {
	0% {
		transform: translateY(100%);
	}

	100% {
		transform: translateY(0%);
	}
}

@include breakpoint(sm) {
	.mainPage {
		overflow: hidden;
	}
}

.animate-effect__slire-right-to-left {
	// transition: transform .8s ease, opacity .6s ease;
	transform: translateX(10%);
	opacity: 0;
	will-change: transform, opacity;

	.js-is-animated & {
		opacity: 1;
		transform: translateX(0)!important;
		transition: transform .8s ease, opacity .6s ease;
	}

	&-2 {
		.js-is-animated & {
			transition-delay: 1.5s;
		}
	}
}

.animate-effect__slire-left-to-right {
	transform: translateX(-10%);
	opacity: 0;
	will-change: transform, opacity;

	.js-is-animated & {
		opacity: 1;
		transform: translateX(0)!important;
		transition: transform .8s ease, opacity .6s ease;
	}
}

.animate-effect__slire-up-to-down {
	transform: translateY(-10%);
	opacity: 0;
	will-change: transform, opacity;

	.js-is-animated & {
		transition: transform .8s ease, opacity .6s ease;
		opacity: 1;
		transform: translateY(0)!important;
	}
}

.animate-effect__slire-down-to-up {
	transform: translateY(10%);
	opacity: 0;
	will-change: transform, opacity;

	.js-is-animated & {
		transition: transform .8s ease, opacity .6s ease;
		opacity: 1;
		transform: translateY(0)!important;
	}
}
