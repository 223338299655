.js-customScroll {
	max-height: 300px; // частный случай
	overflow: hidden;

	.os-host-resize-disabled.os-host-scrollbar-horizontal-hidden > .os-scrollbar-vertical {
		// os-scrollbar
		// os-scrollbar-vertical
		width: 8px;
		height: 100%;
	}

	.os-scrollbar-track {
		// подложка
		border: 1px solid $color-white !important;
		// +вариант без бордера с фоном
	}

	.os-scrollbar-handle {
		// таскалка: варировать цвет
		background-color: $color-white;

		cursor: grab;
	}
}

body {
	//your theme name
	$theme-name: os-theme-zebra-main-menu;

	//horizontal scrollbar
	$scrollbar-horizontal-size: 10px; //horizontal scrollbar height
	$scrollbar-horizontal-padding: 2px;
	$scrollbar-horizontal-track-background: $color-white;
	$scrollbar-horizontal-track-background-hover: $color-white;
	$scrollbar-horizontal-track-background-active: rgba(41, 37, 36, 0.15);
	$scrollbar-horizontal-track-transition: background-color 0.3s;
	$scrollbar-horizontal-handle-min-size: 30px; //horizontal scrollbar handle min width
	$scrollbar-horizontal-handle-max-size: 30px; //horizontal scrollbar handle max width
	$scrollbar-horizontal-handle-background: rgba(255, 180, 65, 0.4);
	$scrollbar-horizontal-handle-background-hover: rgba(255, 180, 65, 0.6);
	$scrollbar-horizontal-handle-background-active: rgba(255, 180, 65, 1);
	$scrollbar-horizontal-handle-transition: background-color 0.3s;

	//vertical scrollbar
	$scrollbar-vertical-size: 12px; //vertical scrollbar width
	$scrollbar-vertical-padding: 2px;
	$scrollbar-vertical-track-background: transparent;
	$scrollbar-vertical-track-background-hover: transparent;
	$scrollbar-vertical-track-background-active: transparent;
	$scrollbar-vertical-track-transition: background-color 0.3s;
	$scrollbar-vertical-handle-min-size: 30px; //vertical scrollbar handle min height
	$scrollbar-vertical-handle-max-size: 1500px; //vertical scrollbar handle max height
	$scrollbar-vertical-handle-background: #cb1d46;
	$scrollbar-vertical-handle-background-hover: rgba(57, 66, 74, 1);
	$scrollbar-vertical-handle-background-active: rgba(57, 66, 74, 1);
	$scrollbar-vertical-handle-transition: background-color 0.3s;

	//scrollbar corner
	$scrollbar-corner-background-color: transparent;

	.#{$theme-name} > .os-scrollbar-horizontal {
		right: $scrollbar-vertical-size;
		height: $scrollbar-horizontal-size;
		padding: $scrollbar-horizontal-padding;
	}

	.#{$theme-name} > .os-scrollbar-vertical {
		bottom: $scrollbar-horizontal-size;
		width: $scrollbar-vertical-size;
		padding: $scrollbar-vertical-padding;
		//border-left: 1px solid #fff;
		//border-right: 1px solid #fff;
	}

	.#{$theme-name}.os-host-rtl > .os-scrollbar-horizontal {
		left: $scrollbar-vertical-size;
		right: 0;
	}

	.#{$theme-name} > .os-scrollbar-corner {
		height: $scrollbar-horizontal-size;
		width: $scrollbar-vertical-size;
		background-color: $scrollbar-corner-background-color;
	}

	.#{$theme-name} > .os-scrollbar-horizontal > .os-scrollbar-track {
		background: $scrollbar-horizontal-track-background;
	}

	.#{$theme-name} > .os-scrollbar-vertical > .os-scrollbar-track {
		background: $scrollbar-vertical-track-background;
		border: 1px solid #ed1846 !important;
	}

	.#{$theme-name} > .os-scrollbar-horizontal > .os-scrollbar-track:hover {
		background: $scrollbar-horizontal-track-background-hover;
	}

	.#{$theme-name} > .os-scrollbar-vertical > .os-scrollbar-track:hover {
		background: $scrollbar-vertical-track-background-hover;
	}

	.#{$theme-name} > .os-scrollbar-horizontal > .os-scrollbar-track.active {
		background: $scrollbar-horizontal-track-background-active;
	}

	.#{$theme-name} > .os-scrollbar-vertical > .os-scrollbar-track.active {
		background: $scrollbar-vertical-track-background-active;
	}

	.#{$theme-name}.os-host-transition > .os-scrollbar-horizontal > .os-scrollbar-track {
		-webkit-transition: $scrollbar-horizontal-track-transition;
		transition: $scrollbar-horizontal-track-transition;
	}

	.#{$theme-name}.os-host-transition > .os-scrollbar-vertical > .os-scrollbar-track {
		-webkit-transition: $scrollbar-vertical-track-transition;
		transition: $scrollbar-vertical-track-transition;
	}

	.#{$theme-name} > .os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle {
		min-width: $scrollbar-horizontal-handle-min-size;
		max-width: $scrollbar-horizontal-handle-max-size;
		background: $scrollbar-horizontal-handle-background;
	}

	.#{$theme-name} > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle {
		min-height: $scrollbar-vertical-handle-min-size;
		//max-height: $scrollbar-vertical-handle-max-size;
		background: $scrollbar-vertical-handle-background !important;
		width: 8px;
	}

	.#{$theme-name} > .os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle:hover {
		background: $scrollbar-horizontal-handle-background-hover;
	}

	.#{$theme-name} > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle:hover {
		background: $scrollbar-vertical-handle-background-hover;
	}

	.#{$theme-name} > .os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle.active {
		background: $scrollbar-horizontal-handle-background-active;
	}

	.#{$theme-name} > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle.active {
		background: $scrollbar-vertical-handle-background-active;
	}

	.#{$theme-name}.os-host-transition > .os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle {
		-webkit-transition: $scrollbar-horizontal-handle-transition;
		transition: $scrollbar-horizontal-handle-transition;
	}

	.#{$theme-name}.os-host-transition > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle {
		-webkit-transition: $scrollbar-vertical-handle-transition;
		transition: $scrollbar-vertical-handle-transition;
	}
}

