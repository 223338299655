$header-width-index: 15vw;
$bgi--y: 60%;

.body--wine {
	// background-color: $whisper;
	background-color: rgb(246, 246, 248);

	.l-layout-wrapper--1 .l-page {
		padding: 0;
		max-width: calc(100vw - 80px);
		width: 100%;
		margin-left: 80px;
		// max-width: 100vw;
		// margin: auto;
	}

	.l-page__inner {
		padding: 0 32px;
	}

	.l-page__content {
		padding-left: 220px;
		// padding-right: 190px;
		padding-right: 12vw;
	}

	.aside {
		width: 170px;
		left: 0;
	}

	.l-page-header {
		@include rem(margin-bottom, 80px);
	}

	.l-layout-wrapper {
		width: 100vw;
		overflow-x: hidden;
	}

	.l-page__content {
		width: 100%;
	}

	.wine-content {
		max-width: 984px;

		@media screen and (min-width: 1600px) {
			margin-left: auto;
			margin-right: auto;
		}
	}

	.title {
		@include font-size(60px);
		// @include rem(margin-bottom, 80px);
		margin-bottom: 0;
		@include font(400, 'Stinger');
	}

	.title-small {
		@include font-size(54px);
		@include font(400, 'Stinger');
		margin-bottom: 0;
	}

	.sub-title {
		@include font(700, 'Stinger');
		@include font-size(54px);
	}

	.bold-text {
		font-weight: 600;
	}

	.appeal {
		@include rem(padding-bottom, 65px);
		@include rem(margin-bottom, 40px);
		border-bottom: 1px solid $color-mid-gray;

		&__title {
			@include font-size(54px);
			line-height: 1.2;
			@include font(400, 'Stinger');
			@include rem(margin-bottom, 32px);
			color: $silver;
		}

		&__image {
			max-width: 35vw;
			margin: auto;

			@include breakpoint(sm) {
				// max-width: 60vw;
				max-width: 80vw;
			}

			@include breakpoint(xs) {
				// max-width: 80vw;
				max-width: 100%;
			}

			opacity: 0;
			transition: opacity .3s ease .9s;

			.anim {
				transition: transform 1s ease .9s;
				transform: translateY(-50px);
			}
		}
	}

	.appeal--1 {
		span:nth-child(5),
		span:nth-child(6),
		span:nth-child(7),
		span:nth-child(8) {
			color: $crimson_2;
		}

		span:nth-child(n+11) {
			color: $pompadour;
		}
	}

	.appeal--2 {
		span:nth-child(3),
		span:nth-child(4) {
			color: $pompadour;
		}

		span:nth-child(n+9) {
			color: $crimson_2;
		}

		.appeal__wrapper {
			align-items: flex-end;
		}
	}

	.appeal--3 {
		span:nth-child(7) {
			color: $crimson_2;
		}

		span:nth-child(n+9) {
			color: $pompadour;
		}
	}

	.text-with-link {
		text-align: center;
		@include font-size(20px);
		width: 60%;
		margin: auto;
		@include rem(margin-top, 60px);

		a {
			text-decoration: none;
			font-weight: 700;

			&:hover,
			&:focus,
			&:active {
				text-decoration: underline;
			}
		}
	}

	article {
		@include rem(margin-bottom, 65px);
	}

	p {
		@include font-size(20px);
	}

	.decor-image {
		position: absolute;
		z-index: -1;
		margin: 0;
	}

	.decor-image--1 {
		display: none;
		// top: -50px;
		// right: -16px;
		top: 0;
		right: 0;
		transform: translate(32px, -50px);
	}

	.decor-image--2 {
		display: none;
		top: -60px;
		right: 0;
	}

	.decor-image--3 {
		display: none;
		// left: -16px;
		left: 0;
		transform: translateX(-16px);
	}

	.decor-image--4 {
		display: none;
		// left: -16px;
		position: relative;
		left: 0;
		transform: translate(-32px, -45%);
	}

	.animated-place {
		position: relative;
		width: 100vw;
		left: calc(50% - 60px);
		transform: translateX(-50%);

		@include breakpoint(sm) {
			transform: none;
			left: -32px;
		}

		@include breakpoint(xs) {
			transform: none;
			left: -16px;
		}
	}

	.lands {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		.sub-title {
			flex: 1 1 60%;
		}

		&__wrapper {
			flex: 0 0 300px;
			width: 300px;

			@include breakpoint(xs) {
				flex: 1 0 100%;
			}
		}

		&__window {
			background-image: url(/images/components/wine/images/land.png);
			background-position: 0% $bgi--y;
			background-size: auto 40%;
			background-repeat: no-repeat;
			animation: landscape 8s linear infinite;
		}
	}

	.decor-image--5 {
		// top: 10%;
		// right: -80px;
		width: 10vw;
		max-width: 200px;
		right: 0;
		transform: translateX(30%);
	}

	.decor-image--6 {
		// top: 30%;
		// left: -340px;
		// left: -160px;
		width: 20vw;
		left: 0;
		max-width: 250px;
		transform: translateX(-50%);

	}

	.decor-image--7 {
		// top: 50%;
		// right: -62px;
		right: 0;
		transform: translate(50%, 50%);
		width: 30vw;
		max-width: 500px;
	}

	.decor-image--8 {
		// top: 37%;
		right: 0;
		transform: translate(10%, -100%);
	}

	.decor-image--9 {
		// top: 50%;
		// left: -300px;
		width: 20vw;
		left: 0;
		max-width: 350px;
		transform: translateX(-50%);
	}

	.decor-image--10 {
		// top: 70%;
		// left: -300px;
		left: 0;
		width: 20vw;
		max-width: 240px;
		transform: translate(-50%, 50%);
	}

	.decor-image--11 {
		top: -100px;
		right: 0;

		width: 23vw;
		max-width: 380px;
		transform: translateX(-50%);

		@include breakpoint(md) {
			transform: none;
		}
	}

	.decor-image--12 {
		// right: -130px;
		width: 16vw;
		max-width: 270px;
		transform: translateX(100px);
		right: 0;
	}

	.decor-image--13 {
		// top: -120px;
		// right: -152px;
		right: 0;
		transform: translate(20%, -20%);
		max-width: 300px;
		width: 18vw;
	}

	.decor-image--14 {
		// top: 80%;
		// left: -260px;
		transform: translateX(-50%);
		left: 0;
		width: 13vw;
		max-width: 210px;
	}

	.decor-image--15 {
		// right: -130px;
		right: 0;
		transform: translateX(60%);
		width: 28vw;
		max-width: 400px;
	}

	.decor-image--16 {
		// left: -240px;
		left: 0;
		transform: translateX(-50%);
		width: 12vw;
		max-width: 212px;
	}

	.decor-image--17 {
		// top: -160px;
		// right: 50%;
		top: 0;
		width: 25vw;
		max-width: 400px;
		transform: translate(-50%, -80%);
		left: 50%;
	}

	@media screen and (min-width: 1790px) {
		.l-layout-wrapper {
			width: 100vw;
			overflow-x: visible;
		}
	}

	@media screen and (max-width: 1440px) {
		// p {
		// 	@include font-size(19px);
		// }

		.appeal__title {
			@include font-size(38px);
		}

		.sub-title {
			@include font-size(38px);
		}
	}

	@include breakpoint(sm) {
		.l-layout-wrapper--1 .l-page {
			max-width: 100vw;
			margin: auto;
		}

		.l-page__content {
			padding: 0;
		}

		.l-page-header {
			display: flex;
			flex-direction: row-reverse;
			justify-content: space-between;
			align-items: flex-end;

			.aside {
				width: auto;
			}

			img {
				max-width: $header-width-index;
			}
		}

		.l-page-header--1 img {
			margin-bottom: -16vw;
		}

		.l-page-header--2 img {
			margin-bottom: -28vw;
		}

		.appeal__title {
			@include font-size(30px);
		}

		.title-small {
			@include rem(margin-bottom, 15px);
		}

		.sub-title {
			@include font-size(30px);
		}

		// p {
		// 	@include font-size(18px);
		// }

		.wine-content {
			margin-right: 0;
		}

		.decor-image--1,
		.decor-image--2,
		.decor-image--3,
		.decor-image--4 {
			display: block;
		}

		.decor-image--5,
		.decor-image--6,
		.decor-image--7,
		.decor-image--8,
		.decor-image--9,
		.decor-image--10,
		.decor-image--11,
		.decor-image--12,
		.decor-image--13,
		.decor-image--14,
		.decor-image--15,
		.decor-image--16,
		.decor-image--17 {
			display: none;
		}

	}

	@include breakpoint(xs) {
		.l-page__inner {
			padding: 0 16px;
		}
	}

	.js-animated-title {
		span {
			display: inline-block;
			opacity: 0;
			transition: opacity .5s ease .3s, transform 1s ease .3s;
		}

		&.is-visible {
			span {
				opacity: 1 !important;
				transform: translate(0px, 0px) !important;
			}

			& +	.appeal__image {
				opacity: 1;
				// transform: translate(0px, 0px);
				.anim {
					transform: translateY(0px);
				}
			}
		}
	}

	.browser-ie & {
		.js-animated-title span,
		.appeal__image,
		.appeal__image .anim {
			opacity: 1 !important;
			transform: translate(0px, 0px) !important;
		}
	}
}

@keyframes landscape {
	0% {
		background-position: 0% $bgi--y;
	}

	100% {
		background-position: 100% $bgi--y;
	}
}
