$accordion--controls-height: 40px;
$accordion--button-bg: $color-celeste;
$accordion--button-bg-hover: $color-ironside-gray;
$accordion--button-bg-active: $color-mine-shaft;
$accordion--button-text-color: $base-text-color;
$accordion--button-text-color-hover: $color-white;
$accordion--button-text-color-active: $color-white;
$accordion--article-bg: $color-eallery;
$accordion--article-close-hover: orange;
$accordion--control-icon-bg: $color-navy-blue;
$accordion--control-icon-color: $color-white;
$accordion--control-item-bg: $color-celeste;
$accordion--control-item-bg-hover: $color-ironside-gray;
$accordion--control-item-bg-active: $color-mine-shaft;
$accordion--control-text-color: $base-text-color;
$accordion--control-text-color-hover: $color-white;
$accordion--control-text-color-active: $color-white;
$accordion--article-close-bg: $base-text-color;
$accordion--article-close-bg-hover: $color-navy-blue;
$accordion--article-close-bg-active: $color-prussian-blue;
$accordion--button-font-size: 20px;

%control-icon {
	content: '';
	display: block;
	position: absolute;
	background-color: $accordion--control-icon-color;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	width: 20px;
	height: 2px;
}

.b-accordion {
	&__section {
		margin-bottom: 1px;
	}

	&__button {
		display: block;
		position: relative;
		padding: 10px 40px 10px 20px;
		text-decoration: none;
		color: $accordion--button-text-color;
		background-color: $accordion--button-bg;
		@include font-size($accordion--button-font-size);
		cursor: pointer;
		transition: all .2s ease-in-out;

		&:visited {
			color: $accordion--button-text-color;

			&:focus {
				color: $accordion--button-text-color;
			}
		}

		&:hover,
		&:focus {
			color: $accordion--button-text-color-hover;
			background-color: $accordion--button-bg-hover;
			padding-left: 35px;

			&:before,
			&:after {
				background-color: $accordion--button-text-color-hover;
			}

			&:visited {
				color: $accordion--button-text-color-hover;
				background-color: $accordion--button-bg-hover;
			}
		}

		&.is-expand,
		&:active {
			padding-left: 35px;
			color: $accordion--button-text-color-active;
			background-color: $accordion--button-bg-active;

			&:before {
				background-color: $accordion--button-text-color-active;
			}

			&:after {
				background-color: $accordion--button-text-color-active;
			}

			&:visited {
				color: $accordion--button-text-color-active;
				background-color: $accordion--button-bg-active;
			}
		}

		&.is-expand {
			&:before {
				transform: rotate(45deg);
			}

			&:after {
				transform: rotate(-45deg);
			}
		}

		&:before,
		&:after {
			content: '';
			position: absolute;
			display: block;
			width: 3px;
			height: 15px;
			background-color: $accordion--button-text-color;
			top: 0;
			bottom: 0;
			margin: auto;
			transition: transform 0.2s ease;
			border-radius: 5px;
		}

		&:before {
			right: 29px;
			transform: rotate(-45deg);

		}

		&:after {
			right: 20px;
			transform: rotate(45deg);
		}
	}

	&__article {
		position: relative;
		margin-top: 0;
		padding: 15px 25px 35px 20px;
		background-color: $accordion--article-bg;
		display: none;

		&.is-expand {
			display: block;

			.b-accordion__article-close {
				&:before {
					transform: rotate(45deg);
				}

				&:after {
					transform: rotate(-45deg);
				}
			}
		}
	}

	&__article-close {
		position: absolute;
		display: block;
		width: 32px;
		height: 32px;
		right: 10px;
		background: transparent;
		bottom: 0px;
		cursor: pointer;

		&:before,
		&:after {
			content: '';
			position: absolute;
			display: block;
			width: 3px;
			height: 15px;
			background-color: $accordion--button-text-color;
			top: 0;
			bottom: 0;
			margin: auto;
			transition: transform 0.2s ease;
			border-radius: 5px;
		}

		&:before {
			right: 19px;
			transform: rotate(-45deg);

		}

		&:after {
			right: 10px;
			transform: rotate(45deg);
		}

		&:hover,
		&:focus {
			background: transparent;

			&:before {
				background-color: $accordion--article-close-bg-hover;
			}

			&:after {
				background-color: $accordion--article-close-bg-hover;
			}
		}

		&:active {
			background: transparent;

			&:before {
				background-color: $accordion--article-close-bg-active;
			}

			&:after {
				background-color: $accordion--article-close-bg-active;
			}
		}

		&.is-hidden {
			display: none;
		}
	}

	&__control {
		padding-top: 10px;
	}

	&__control-item {
		display: inline-block;
		height: $accordion--controls-height;
		line-height: $accordion--controls-height;
		margin-bottom: 10px;
		background-color: $accordion--button-bg;
		color: $accordion--control-text-color;
		padding: 0;
		cursor: pointer;

		&:first-child {
			margin-right: 10px;
		}

		&:hover {
			background-color: $accordion--control-item-bg-hover;
			color: $accordion--control-text-color-hover;
		}

		&:active {
			background-color: $accordion--control-item-bg-active;
			color: $accordion--control-text-color-active;
		}
	}

	&__control-icon {
		display: block;
		position: relative;
		height: 100%;
		width: $accordion--controls-height;
		float: left;
		background-color: $accordion--control-icon-bg;
	}

	&__control-text {
		padding-left: 15px;
		padding-right: 15px;
	}

	&__control-item--expand {
		.b-accordion__control-icon {
			&:before,
			&:after {
				@extend %control-icon;
			}

			&:before {
				transform: rotate(0deg);
			}

			&:after {
				transform: rotate(90deg);
			}
		}
	}

	&__control-item--collapse {
		.b-accordion__control-icon:before {
			@extend %control-icon;
		}
	}
}

.b-accordion--career {
	.b-accordion__button {
		display: inline-block;
		background: transparent;
		font-size: 16px;
		color: $color-white;
		position: relative;
		padding-left: 34px;
		padding-right: 0;

		span {
			border-bottom: 1px dotted;
		}

		&:hover {
			color: #ED1846;

			&:before {
				@include svg(22px, 22px, '/images/icons/arrow-right-hover.svg');
			}
		}

		&:focus {
			padding-left: 34px;
		}

		&.is-expand {
			color: #ED1846;

			&:before {
				@include svg(22px, 22px, '/images/icons/arrow-right-active.svg');
				transform: rotate(90deg);
				color: #ED1846;
				font-weight: 600;
			}
		}

		&:after {
			background: transparent;
		}

		&:before {
			content: '';
			background: transparent;
			left: 0;
			right: auto;
			width: 22px;
			transform: none;
			height: 22px;
			position: absolute;
			@include svg(22px, 22px, '/images/icons/arrow-right-3.svg');
		}
	}

	.b-accordion__article {
		background: transparent;
		color: $color-white;
		padding-left: 34px;
	}
}
