.news-page {
	&__date {
		font-size: 24px;
		color: #231f20;
	}

	&__header {
		color: #231f20;
		font-size: 40px;
		border-bottom: 1px solid #ed1846;
		padding-bottom: 10px;
		margin-bottom: 1.5em;
		margin-top: 1em;
	}

	&__image {
	}

	&__content-image {
		max-width: 1100px;
		width: 100%;
		max-height: 600px;
		height: 600px;
		background-size: cover;
		background-position: center center;
		background-image: url(/images/content/ru/news-pages/news-image-id-31-content.png);

		@media (max-width: $site-width) {
			height: 33vw;
		}

		@include breakpoint(sm) {
			height: 53vw;
		}
	}

	img {
		margin-bottom: 1.5em;
		margin-top: 1.5em;
		margin-left: 0;
	}

	.list-header {
		font-size: 22px;
	}

	&__text {
		color: #231f20;
		font-size: 18px;
		font-weight: 100;

		p {
			font-size: 20px;
			font-weight: 100;
		}
	}

	&__image + &__text {
		margin-top: 2em;
	}

	.row + &__text {
		margin-top: 2em;
	}

	&__image + .row {
		margin-top: 2em;
	}
}

.body--news {
	background-color: #f6f6f8;
	// background-image: url(/images/content/project-bg.png);
	// background-size: cover;
	//background-repeat: no-repeat;

	.l-page__content {
		width: 100%;
	}
}

@media (max-width: 798px) {
	.body--news {
		.news-page {
			&__header {
				font-size: 30px;
			}
		}
	}
}
