.body--contacts {
	background-color: $color-black;

	.l-layout-wrapper {
		padding-top: 0;
		min-height: 100%;

		.l-page {
			padding-left: 80px;
			padding-right: 0;
			flex-direction: row;
		}
	}

	.l-page__inner {
		width: 100%;
	}

	.l-page__content {
		width: 100%;
		padding-bottom: 0;
	}

	.content-area {
		// height: 100%;
	}
}

.contacts {
	height: 100%;
	display: flex;
	flex-direction: column;
	padding: 0 25px;

	&__title {
		@extend .header-main;
		color: $color-white;
		margin-bottom: 2vh;
	}

	&__info {
		@include font-size(26px);
		@include font(400);
		color: $color-white;

		p {
			margin-bottom: 0;
			@include font-size(24px);
			line-height: 1.1;
		}

		span {
			color: #6d6e70;
			@include font(400);
		}

		a {
			color: $color-white !important;
			text-decoration: none;
			line-height: 1;
			// border-bottom: 1px solid #ec1847;
			// transition: 0.3s ease;

			&:hover,
			&:focus {
				border-bottom-color: transparent;
			}
		}

		.body--contacts {
			height: 100%;
			overflow: hidden;
		}

		& + .contacts__info {
			margin-top: 25px;
		}
	}

	&__info-address {
		a {
			// display: none;
			@include webfont-icon($webfont-icon--location);
			color: $color-white;
			transition: color 0.3s ease;
			border: none;

			&:before {
				font-size: 0.9em;
				vertical-align: baseline;
				margin-right: 0;
			}

			&:hover,
			&:focus {
				color: #ec1847 !important;
			}
		}
	}

	// &__form-btn {
	// 	display: none;
	// 	@include font-size(25px);
	// 	@include font(600);
	// 	margin-top: 16px;
	// 	border-bottom: 1px solid #ec1847;
	// 	line-height: 1.15;
	// 	transition: 0.3s ease;
	// 	cursor: pointer;

	// 	&:hover,
	// 	&:focus {
	// 		border-bottom-color: transparent;
	// 	}
	// }

	&__subtitle {
		@include font-size(30px);
		@include font(600);
		color: $color-white;
	}

	&__data {
		padding-top: 6vh;
		padding-bottom: 4vh;
		max-width: 1450px;
		margin: 0 auto;
		width: 100%;
	}

	&__map {
		position: relative;
		display: flex;
		width: 100vw;
		margin-left: -40px;
		left: 50%;
		transform: translateX(-50%);
		padding-left: 80px;
		flex: 1 auto;

		height: 50vh;
		max-height: 30vh;
	}

	&__mini-map.link--external {
		// margin-bottom: 0.833em; // 25px
		margin-bottom: 2vh;
		text-decoration: underline;
		display: inline-flex;
		align-items: center;

		&:before {
			content: '';
			display: block;
			// width: 3em;
			// height: 3em;
			width: 75px; //IE fix
			height: 75px; //IE fix
			background-repeat: no-repeat;
			background-image: url('/images/icons/link-map.svg');
			background-size: cover;
			background-position: center;
			margin-right: 1em;
			font-size: inherit;
			transition: transform .4s ease;
			transform: scale(.8);
		}

		&:hover {
			text-decoration: none;

			&:before {
				transform: scale(1);
			}
		}

		.link-text {
			color: white;
		}
	}

	// &__mini-map-icon {
	// 	background-repeat: no-repeat;
	// 	background-image: url('/images/icons/link-map.svg');
	// 	background-size: auto 45%;
	// 	background-position: center center;
	// }
}

.contacts-row {
	width: 100%;
	height: 100%;
	display: flex;
	flex: 0 1 auto;
	flex-wrap: wrap;

	// подбор учитывая слайдер и макс.экраны FullHD
	max-width: 1500px;
	margin-left: auto;
	margin-right: auto;

	.social__icons {
		width: 90px;
		transform: scale(.8);
		transition: transform .4s ease, opacity .4s ease;
		opacity: .7;

		&:hover {
			transform: scale(1);
			opacity: 1;
		}
	}
}

.contacts-col--info {
	flex-basis: 45%;
	max-width: 45%;

	&:last-child {
		margin-top: 126px;

		@media screen and (max-width: 1440px) {
			margin-top: 90px;
		}

		@media screen and (max-width: 980px) {
			margin-top: 0;
		}
	}
}

.contacts-col--form {
	flex-basis: 55%;
	max-width: 55%;
	padding-left: 5%;
}

@mixin contacts-to-col() {
	.contacts-col--info {
		margin-bottom: 2rem;
	}

	.contacts-col--form,
	.contacts-col--info {
		flex-basis: 100%;
		max-width: 100%;
		padding-left: 1rem;
		padding-right: 1rem;
	}
}

@mixin contacts-size-s() {
	.contacts {
		&__info p {
			font-size: 24px;
		}

		&__info,
		&__subtitle {
			font-size: 26px;
		}
	}

	.contacts-row {
		.social__icons {
			width: 70px;
		}

		.social__item {
			margin-right: 16px;
		}
	}
}

.body--services,
.body--contacts {
	@media screen and (max-width: 980px) {
		@include contacts-to-col();
	}
}

.body--main-page {
	@include breakpoint(sm) {
		@include contacts-to-col();
	}

	@media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
		@include contacts-size-s();
	}
}

@include breakpoint(sm) {
	.body--contacts {
		.l-layout-wrapper .l-page {
			padding-left: 0;
		}

		.contacts {
			padding: 0 16px;

			&__data {
				padding-top: 80px;
			}
		}

		.contacts-row {
			width: auto;
			margin-left: -1rem;
			margin-right: -1rem;
		}
	}
}

@include breakpoint(xs) {
	@include contacts-size-s();
}
