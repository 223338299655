.b-projects {
	display: flex;
	flex-direction: row;

	&__plates {
		flex-shrink: 1;
		flex-grow: 1;
		padding-right: 45px;
	}

	&__plates-row.is-locked {
		.b-project-list-item:not(.shuffle-item--hidden) {
			position: relative !important;
			transform: none !important;
		}

		.b-project-list-item.shuffle-item--hidden {
			opacity: 0 !important;
			transition-duration: 0s !important;
			position: absolute;
			top: 0px;
			left: 0px;
			visibility: hidden;
		}
	}

	&__filter-wrapper {
		flex-grow: 0;
		flex-shrink: 0;
		width: 190px;
		z-index: 1;
	}

	&__filter-trigger {
		margin-bottom: 25px;
	}
}

.b-project-list-item {
	width: 100%;
	height: 100%;
	position: relative;

	&__link-wrapper {
		display: block;
		height: 100%;
	}

	&__image-wrapper {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		picture {
			width: 100%;
			height: 100%;

			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}
	}

	&__title-wrapper {
		min-height: 35px;
		background: rgba(0, 0, 0, 0.8);
		color: #fff;
		font-size: 18px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 6px 10px;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		opacity: 1;
		transition: all 0.2s linear;
	}

	&__title-wrapper--arrow {
		padding-right: 55px;
		@include webfont-icon($webfont-icon--arrow-right5);

		&:before {
			position: absolute;
			right: 25px;
			border: 1px solid #fff;
			border-radius: 50%;
			font-size: 12px;
			width: 2em;
			height: 2em;
			line-height: 2em;
			text-align: center;
		}
	}

	&__title {
		width: 100%;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		line-height: 1.2;
	}

	&__sub-title {
		font-size: .7em;
		@include font(300);
		line-height: 1;
		width: 100%;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		margin-top: 5px;
	}

	&__cover {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background: rgba($color-cardinal, 0.8);
		opacity: 0;
		transition: all 0.2s linear;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: #fff;
	}

	&__cover-title {
		font-size: 40px;
		@include font(300);
		text-align: center;
	}

	&__cover-sub-title {
		width: 100%;
		font-size: 20px;
		@include font(300);
		text-align: center;
	}

	&:hover &__cover {
		opacity: 1;
	}

	&:hover &__title-wrapper {
		opacity: 0;
	}
}

.b-project-list-item--plate {
	height: 300px;

	.b-project-list-item__title-wrapper {
		font-size: 24px;
	}
}

@mixin project-button() {
	.l-navigation-panel__item--link {
		display: none;
		background: #ec1847;
		border-radius: 50%;
		height: 50px;
		width: 50px;
		text-decoration: none;
		position: absolute;
		z-index: 1;
		top: 27%;
		right: -24px;
		@include webfont-icon($webfont-icon--arrow-back);
		transition: background 0.2s ease, transform 0.3s ease, right 0.3s ease;

		&:before {
			font-size: 20px;
			position: absolute;
			margin: auto;
			top: 15px;
			left: 0;
			right: 0;
			color: $color-white;
			transition: color 0.2s ease, ;
		}

		&:hover {
			background-color: #cc0934;
		}

		&.is-hidden {
			transform: scale(0);
		}
	}

	@media (max-width: 1650px) {
		.l-navigation-panel__item--link {
			right: 0;
			left: 0;
			margin: auto;
			background: transparent;

			&:hover,
			&:focus,
			&:active {
				background-color: $color-white;

				&:before {
					color: $color-black;
				}
			}
		}
	}

	@media (max-width: 798px) {
		.l-layout-wrapper--1 {
			.l-navigation-panel__item--link {
				right: 16px;
				left: 0;
				margin-left: auto;
				height: 50px;
				width: 50px;
				background: #ec1847;
				margin-right: 0;
				top: 100%;
				margin-top: 10px;
				text-decoration: none;

				&:hover,
				&:active,
				&:focus {
					background: #e00b3a;
				}
			}
		}
	}
}

.body--news,
.body--projects,
.body--analytics {
	@include project-button();
}

.body--news {
	.l-navigation-panel__item--link-news {
		display: block;
	}
}

.body--projects {
	.l-navigation-panel__item--link-projects {
		display: block;
	}
}

.body--analytics {
	.l-navigation-panel__item--link-analytics {
		display: block;
	}
}
