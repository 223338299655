$bg-color-category--event: #553c99;
$bg-color-category--announcement: #ba095d;
$bg-color-category--company-news: #7261bc;
$bg-color-category--achievement: #4367b0;
// $bg-color-category--Новый-тег: #a53bb0;;
// $bg-color-category--workshop: #45a4a1;
.news {
	.news-main {
		min-height: 500px;
		background-size: cover;
		background-position: center center;
		display: flex;
		padding-right: 30px;
		padding-left: 30px;
		padding-bottom: 30px;
		flex-direction: column;
		position: relative;
		overflow: hidden;

		&:hover {
			.news-main__image {
				transform: scale(1.1);

				&:before {
					background-color: rgba(35, 31, 32, 0.4);
				}
			}
		}

		&__image {
			position: absolute;
			width: 100%;
			height: 100%;
			// background-size: cover;
			left: 0;
			top: 0;
			transition: transform 0.65s;
			display: flex;
			justify-content: center;
			align-items: center;
			overflow: hidden;

			&:before {
				content: '';
				position: absolute;
				width: 100%;
				height: 100%;
				background-color: rgba(35, 31, 32, 0.85);
				left: 0;
				top: 0;
				z-index: 0;
				transition: background-color 0.65s;
			}

			picture {
				flex-shrink: 0;
				min-width: 100%;
				min-height: 100%;
			}

			img {
				display: block;
			}
		}

		&__category {
			position: absolute;
			bottom: 0;
			left: 0;
			font-size: 16px;
			color: $color-white;
			display: inline-block;
			background: #ED1846;
			padding-left: 10px;
			padding-right: 25px;
			padding-top: 2px;
			padding-bottom: 2px;
			align-self: flex-start;
			z-index: 1;
		}

		&__date {
			font-size: 18px;
			color: $color-white;
			padding-top: 4%;
			z-index: 1;
			font-weight: 400;
		}

		&__text--link {
			color: $color-white !important;
			text-decoration: none;

			&:hover,
			&:active,
			&:focus {
				text-decoration: underline;
			}
		}

		&__text {
			font-size: 40px;
			color: $color-white;
			width: 55%;
			line-height: 0.9;
			padding-top: 25px;
			z-index: 1;
		}
	}

	.news-block {
		background-size: cover;
		background-position: center center;
		display: flex;
		flex-direction: column;
		height: 100%;
		overflow: hidden;
		position: relative;

		&:before {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			transition: all 0.65s ease;
			background-color: rgba(35, 31, 32, 0.85);
			z-index: 1;
		}

		&:hover {
			&:before {
				background-color: rgba(237, 24, 70, 0.85);
			}

			.news-block__image {
				transform: scale(1.15);
			}

			.news-block__category {
				opacity: 0;
			}
		}

		&__image {
			background-size: cover;
			height: 100%;
			width: 100%;
			position: absolute;
			transition: all 0.65s;
		}

		&__category {
			position: absolute;
			bottom: 0;
			left: 0;
			font-size: 16px;
			color: $color-white;
			display: inline-block;
			background: #553B96;
			padding-left: 10px;
			padding-right: 25px;
			padding-top: 2px;
			padding-bottom: 2px;
			align-self: flex-start;
			z-index: 1;
			opacity: 1;
			transition: opacity 0.3s;
		}

		&__text {
			font-size: 26px;
			color: $color-white;
			font-weight: 400;
		}

		&__content {
			font-weight: 100;
			padding: 25px;
			line-height: 1;
			height: 100%;
			display: flex;
			flex-direction: column;
			position: relative;
			z-index: 1;
		}

		&__date {
			font-size: 18px;
			color: $color-white;
			padding-bottom: 15px;
			font-weight: 400;
		}
	}

	&__preloader {
		display: none;
		min-width: 80px !important;
		min-height: 80px !important;
		width: 80px;
		height: 80px;
		margin: 16px auto 0;

		&:after {
			background-color: transparent !important;
		}
	}

	&__link {
		text-decoration: none;
		height: 100%;
		min-height: 400px;

		&:hover,
		&:active {
			text-decoration: none;
		}
	}
}

// Здесь создаются модификаторы с цветами для категорий новостей
.news {
	.news-main,
	.news-block {
		&__category {
			&--announcement {
				background-color: $bg-color-category--announcement;
			}

			&--event {
				background-color: $bg-color-category--event;
			}

			&--company-news {
				background-color: $bg-color-category--company-news;
			}

			// &--workshop {
			// 	background-color: $bg-color-category--workshop;
			// }

			&--achievement {
				background-color: $bg-color-category--achievement;
			}

			/* &--nagrada {
				background-color: $bg-color-category--nagrada;
			} */
		}
	}
}

.body--news-all {
	.l-layout-wrapper--1 {
		max-width: 1700px;
	}
}

.body--services {
	.news {
		.news-main {
			// основной размер раздела
			min-height: auto;
			height: 60vh;
			max-height: 480px;

			&__text {
				@include font-size(32px);
				width: 70%;
			}

			@include breakpoint(md) {
				height: 50vh;
			}
		}

		.news-block__text {
			@include font-size(22px);
		}

		&__card-wrapper {
			flex: 1 1 50%;

			&:first-child {
				margin-bottom: 20px;
			}
		}

		&__card-wrapper--with-link {
			position: relative;
		}

		&__link-see-more {
			position: absolute;
			left: 0;
			bottom: 100%;
			margin-bottom: 10px;
			@include font-size(24px);
			color: $color-white;
			text-decoration: underline !important;

			@extend .link--animated;
			@extend .link--animated-1;
		}
	}
}

@media (max-width: 900px) {
	.news {
		.news-main {
			&__text {
				width: 100%;
				font-size: 30px;
			}

			&__date {
				margin-top: 50px;
			}
		}
	}
}

@media (max-width: 1065px) {
	.news {
		.col-news {
			flex-basis: 50%;
			max-width: 50%;
		}
	}
}

@media (max-width: 520px) {
	.news {
		.col-news {
			flex-basis: 100%;
			max-width: 100%;
		}
	}
}

@media (max-width: 798px) {
	.news {
		// .news-block {
		// 	height: auto;
		// }

		.col-news {
			padding-left: 0;
			padding-right: 0;
		}
	}
}

@media (max-width: 650px) {
	.news {
		.col-news {
			flex-basis: 100%;
			max-width: 100%;
		}
	}
}

@media(max-width: 1024px) {
	.browser-ie {
		.body--news-all {
			.l-page__content {
				width: 100%;
			}
		}
	}
}
