// tzar-core/src/gulp/utils/webfont-template.scss - файл-шаблон для генерации иконочного шрифта
// tzar-template/src/styles/webfont.scss - генерируемый файл шрифта, генерируется автоматически при сборке!

$icon_font_name:"webfont";

$webfont-icon--analitika_i_konsalting: "\E001";
$webfont-icon--arrow-back: "\E002";
$webfont-icon--arrow-external-link: "\E003";
$webfont-icon--arrow-right5: "\E004";
$webfont-icon--arrow: "\E005";
$webfont-icon--brochure: "\E006";
$webfont-icon--close1: "\E007";
$webfont-icon--compare: "\E008";
$webfont-icon--corporate-design: "\E009";
$webfont-icon--corporate-reports: "\E00A";
$webfont-icon--cross_2: "\E00B";
$webfont-icon--design_i_poligrafiya: "\E00C";
$webfont-icon--digital: "\E00D";
$webfont-icon--doverie_i_priznanie: "\E00E";
$webfont-icon--file-excel: "\E00F";
$webfont-icon--gears: "\E010";
$webfont-icon--glossary_ru: "\E011";
$webfont-icon--glossaty_en: "\E012";
$webfont-icon--gr-i1: "\E013";
$webfont-icon--gr-i2: "\E014";
$webfont-icon--gr-i3: "\E015";
$webfont-icon--gr-i4: "\E016";
$webfont-icon--gr2: "\E017";
$webfont-icon--gr3: "\E018";
$webfont-icon--gr4: "\E019";
$webfont-icon--icon-ar: "\E01A";
$webfont-icon--icon-esef: "\E01B";
$webfont-icon--icon-esg: "\E01C";
$webfont-icon--icon-or: "\E01D";
$webfont-icon--icon_books: "\E01E";
$webfont-icon--icon_flag: "\E01F";
$webfont-icon--icon_hands: "\E020";
$webfont-icon--icon_spin: "\E021";
$webfont-icon--info: "\E022";
$webfont-icon--interactive-reports: "\E023";
$webfont-icon--lang-en: "\E024";
$webfont-icon--lang-ru: "\E025";
$webfont-icon--list-arrow: "\E026";
$webfont-icon--location: "\E027";
$webfont-icon--microphone: "\E028";
$webfont-icon--multimedia: "\E029";
$webfont-icon--my-report: "\E02A";
$webfont-icon--next: "\E02B";
$webfont-icon--pdf_3: "\E02C";
$webfont-icon--prev: "\E02D";
$webfont-icon--razrabotka_kontenta: "\E02E";
$webfont-icon--reload: "\E02F";
$webfont-icon--search: "\E030";
$webfont-icon--tools_5: "\E031";
$webfont-icon--up_arrow: "\E032";
$webfont-icon--zebra-logo: "\E033";
$webfont-icon--zoom-in: "\E034";

@mixin webfont-icon($icon-code, $after-or-before: 'before') {
	&:#{$after-or-before} {
		content: #{'"' + $icon-code + '"'};
		display: inline-block;
		font-family: $icon_font_name;
		font-style: normal;
		line-height: 1;
		color: currentColor;
		-webkit-font-smoothing: antialiased;
		speak: none;
	}
}

@font-face {
	font-family: $icon_font_name;
	src: url('/fonts/webfont/webfont.woff') format('woff'),
	url('/fonts/webfont/webfont.woff2') format('woff2'),
	url('/fonts/webfont/webfont.svg#webfont') format('svg');
}

.webfont-icon {
	font-family: $icon_font_name;
	display: inline-block;
	vertical-align: middle;
	font-style: normal;
	speak: none;
	color: currentColor;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
}

.webfont-icon--analitika_i_konsalting:before {
	content: '\E001';
}

.webfont-icon--arrow-back:before {
	content: '\E002';
}

.webfont-icon--arrow-external-link:before {
	content: '\E003';
}

.webfont-icon--arrow-right5:before {
	content: '\E004';
}

.webfont-icon--arrow:before {
	content: '\E005';
}

.webfont-icon--brochure:before {
	content: '\E006';
}

.webfont-icon--close1:before {
	content: '\E007';
}

.webfont-icon--compare:before {
	content: '\E008';
}

.webfont-icon--corporate-design:before {
	content: '\E009';
}

.webfont-icon--corporate-reports:before {
	content: '\E00A';
}

.webfont-icon--cross_2:before {
	content: '\E00B';
}

.webfont-icon--design_i_poligrafiya:before {
	content: '\E00C';
}

.webfont-icon--digital:before {
	content: '\E00D';
}

.webfont-icon--doverie_i_priznanie:before {
	content: '\E00E';
}

.webfont-icon--file-excel:before {
	content: '\E00F';
}

.webfont-icon--gears:before {
	content: '\E010';
}

.webfont-icon--glossary_ru:before {
	content: '\E011';
}

.webfont-icon--glossaty_en:before {
	content: '\E012';
}

.webfont-icon--gr-i1:before {
	content: '\E013';
}

.webfont-icon--gr-i2:before {
	content: '\E014';
}

.webfont-icon--gr-i3:before {
	content: '\E015';
}

.webfont-icon--gr-i4:before {
	content: '\E016';
}

.webfont-icon--gr2:before {
	content: '\E017';
}

.webfont-icon--gr3:before {
	content: '\E018';
}

.webfont-icon--gr4:before {
	content: '\E019';
}

.webfont-icon--icon-ar:before {
	content: '\E01A';
}

.webfont-icon--icon-esef:before {
	content: '\E01B';
}

.webfont-icon--icon-esg:before {
	content: '\E01C';
}

.webfont-icon--icon-or:before {
	content: '\E01D';
}

.webfont-icon--icon_books:before {
	content: '\E01E';
}

.webfont-icon--icon_flag:before {
	content: '\E01F';
}

.webfont-icon--icon_hands:before {
	content: '\E020';
}

.webfont-icon--icon_spin:before {
	content: '\E021';
}

.webfont-icon--info:before {
	content: '\E022';
}

.webfont-icon--interactive-reports:before {
	content: '\E023';
}

.webfont-icon--lang-en:before {
	content: '\E024';
}

.webfont-icon--lang-ru:before {
	content: '\E025';
}

.webfont-icon--list-arrow:before {
	content: '\E026';
}

.webfont-icon--location:before {
	content: '\E027';
}

.webfont-icon--microphone:before {
	content: '\E028';
}

.webfont-icon--multimedia:before {
	content: '\E029';
}

.webfont-icon--my-report:before {
	content: '\E02A';
}

.webfont-icon--next:before {
	content: '\E02B';
}

.webfont-icon--pdf_3:before {
	content: '\E02C';
}

.webfont-icon--prev:before {
	content: '\E02D';
}

.webfont-icon--razrabotka_kontenta:before {
	content: '\E02E';
}

.webfont-icon--reload:before {
	content: '\E02F';
}

.webfont-icon--search:before {
	content: '\E030';
}

.webfont-icon--tools_5:before {
	content: '\E031';
}

.webfont-icon--up_arrow:before {
	content: '\E032';
}

.webfont-icon--zebra-logo:before {
	content: '\E033';
}

.webfont-icon--zoom-in:before {
	content: '\E034';
}

