.body--about {

	.about_title {
		font-size: 75px;
		margin: 0;
		line-height: 1;
		@include font(800);
		// color: $color-torch;
		color: #000;
		text-transform: uppercase;
		margin-bottom: 60px;

		@include breakpoint(sm) {
			font-size: 40px;
		}
	}

	.contact {
		color: #000;

		&__title {
			@include font(600);
			font-size: 22px;
			margin-bottom: 0.4em;
		}

		&__title-sub {
			@include font(400);
		}

		&__data {
			font-size: 18px;
			margin-top: 0.2em;
		}

		&__name {
			color: $color-torch;
			@include font(600);
		}

		&__link {
			color: #000 !important;
		}

		&__row {
			display: flex;

			& + .contact__row {
				margin-top: 5.5vh;
			}
		}

		&__col + &__col {
			margin-left: 100px;
		}
	}

	.contact + .contact {
		margin-top: 40px;
	}

	.contact--m {
		.contact {
			&__title {
				font-size: 22px;
			}

			&__data {
				font-size: 18px;
			}
		}
	}

	.contact-text {
		@include font-size(18px);
		max-width: 830px;
	}

	.contact-content {
		max-width: 540px;
	}

	@media (max-width: 1500px) {
		.contact--m .contact__title {
			font-size: 24px;
		}

		.contact-content {
			max-width: 500px;
		}
	}

}
