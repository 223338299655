.career {
	.row-career {
		justify-content: space-between;

		.col-contact {
			flex-basis: 30.33333%;
			max-width: 30.33333%;
			flex-direction: column;
			display: flex;
			flex-grow: 0;
			flex-shrink: 1;
			padding-right: 15px;
			padding-left: 15px;
		}
	}

	h2 {
		color: $color-white;
		font-size: 40px;
		margin-bottom: 1.5em;
	}

	.header-line-bottom {
		font-size: 30px;
		border-bottom: 1px solid #ED1846;
		font-weight: 600;
		color: $color-white;
		padding-bottom: 16px;
		margin-top: 2em;
	}

	.b-tabs--career {
		ul {
			margin-top: 5px;

			li {
				margin: 0;
				padding-left: 0.8em;
				@include webfont-icon($webfont-icon--list-arrow);

				&:before {
					font-size: 9px;
					color: #ED1846;
					background-color: transparent;
				}

				ul {
					li {
						padding-left: 0;

						&:before {
							content: "";
							display: block;
							position: absolute;
							width: 3px;
							height: 3px;
							border-radius: 100%;
							top: 1.163em;
							left: -10px;
							background-color: #ED1846;
						}
					}
				}
			}
		}
	}

	&__contact {
		padding: 25px;
		background: #050506;
		margin-top: -18px;
	}

	.contact-note {
		color: $color-white;
		font-size: 16px;
		margin-top: 1em;

		a {
			color: #ED1846;
			text-decoration: underline;

			&:hover {
				color: $color-white;
			}
		}
	}
}

@if ($responsive) {
	@media (max-width: 1660px) {
		.career .row-career .col-contact {
			flex-basis: 35.3%;
			max-width: 35.3%;
		}
	}

	@media (max-width: 1460px) {
		.career .row-career .col-contact {
			flex-basis: 39.3%;
			max-width: 39.3%;
		}
	}

	@include breakpoint(md) {
		.career {
			.row-career {
				.col-contact {
					flex-basis: 100%;
					max-width: 100%;
					margin-top: 2em;
				}
			}

			.career__contact {
				margin-top: 0;
			}
		}

		.body--career {
			.l-page__content {
				width: 100%;
			}
		}
	}
}

@media (max-width: 798px) {
	.b-tabs--career .b-tabs__nav-item {
		border-left: none !important;
	}
}

.body--career {
	p {
		font-size: 20px;
	}
	header {
		a {
			span {
				font-size: 18px;
			}
		}
	}
}
