.l-layout-wrapper--1 {
	min-height: 100vh;
	width: 100%;
	margin: 0 auto;
	//max-width: 1600px;
	display: flex;
	flex-direction: column;
	max-width: 1800px;
	padding-top: 100px;
	//padding-top: 150px;

	.browser-ie & {
		height: 100%;
	}

	.l-layout-wrapper__header {
		background-color: $header-bg;
		flex: none;
		@include rem(padding-bottom, 10px);
	}

	.l-page {
		display: flex;
		flex-direction: column;
		padding: 0 32px 0 112px;
	}

	.l-page__title {
		margin-bottom: 60px;
	}

	.l-page__inner {
		display: flex;
		flex-direction: row;
	}

	.l-layout-wrapper__page {}

	.l-layout-wrapper__footer {
		background-color: $footer-bg;
		flex: none;
	}

	.l-layout-wrapper__navigation {
		position: fixed;
		left: 0;
		top: 0;
		width: 80px;
		height: 100%;
		z-index: 100;

		&.is-expand {
			z-index: 1000;

			.l-navigation-panel__item--logo {
				overflow: visible;
			}
		}
	}

	.l-header {
		background: #fff;
	}

	.l-header-top-line {
		display: flex;
		align-items: center;
		@include rem(margin-bottom, 10px);
		//@include rem(padding, 10px 0);
		//border-bottom: 1px solid #000;

		&__logo {
			width: 100px;
			display: none;
			flex-basis: 100px;
			flex-shrink: 0;
		}

		&__title {
			flex-shrink: 0;
			flex-grow: 0;
			flex-basis: 300px;
			width: 300px;
		}

		&__tools {
			display: flex;
			justify-content: flex-end;
			flex-grow: 1;
		}
	}

	.l-header-top-nav {
		display: flex;

		&__logo {
			flex-basis: 100px;
			flex-shrink: 0;
			width: 100px;
		}

		&__menu {
			flex-grow: 1;
			flex-shrink: 1;
		}
	}

	.l-page {
		&__nav-breadcrumbs {
			width: 100%;
			max-width: calc(100% - 90px); // минус ширина page-nav'a
		}
	}

	.l-buttons-area {
		display: flex;
		align-items: center;

		&__item {
			@include rem(margin-left, 5px);
		}

		&__search {
			.search {
				width: auto;
				display: block;
			}
		}

		&__dc {
			@include rem(margin-right, 30px);
		}

		&__lang {
			.b-lang {

			}

			.b-lang--single {

			}

			.b-lang--multi-buttons {

			}
		}

		&__buttons-panel {

		}

		&__mobile-nav-panel {
			display: none;
		}
	}

	.l-footer {
		display: flex;
		@include rem(padding-top, 10px);
		@include rem(padding-bottom, 10px);

		&__inner {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
		}

		&__nav {
			text-align: right;
		}
	}

	.l-page__title {}
}

.l-navigation-panel {
	display: flex;
	flex-direction: column;
	text-align: center;
	height: 100%;
	transition: all 0.2s linear;
	background: #000;
	position: relative;

	&:after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		width: 1px;
		height: 100%;
		background: $color-white;
		transition: all 0.2s linear;
	}

	&__item {
		height: 100px;
		transition: all 0.2s linear;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;

		&:hover,
		&.is-active {
			background: rgba(255, 255, 255, 0.13);
		}
	}

	&__item--link-projects {
		display: none;
	}

	&__item--link-news {
		display: none;
	}

	&__item--logo {
		margin-bottom: 0;
		//margin-bottom: 35px;
		position: relative;
		overflow: hidden;
		z-index: 10;

		.b-logo {
			width: 125px;
			position: absolute;
			left: 14px;
			top: 50%;
			transform: translateY(-50%);

			.sign * {
				transition: all 0.2s linear;
			}

			.letters * {
				transition: all 0s linear;
			}

			.letters path {
				fill: transparent
			}
		}

		&.is-active,
		&:hover {
			background: transparent !important;
		}
	}

	&__item--menu {
		margin-bottom: auto;
		height: 50px;

		.l-main-menu-wrapper {
			width: 100%;
			height: 100%;
		}

		.l-main-menu-wrapper__trigger {
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.b-burger-button {
			width: 100%;
			height: 100%;
		}

		&.is-active,
		&:hover {
			background: transparent !important;
		}
	}

	&__item--download-pdf {
		.b-buttons-panel {
			width: 100%;
			height: 100%;
		}

		.b-buttons-panel__trigger {
			width: 100%;
			height: 100%;
		}
	}

	&__item--search {
		.b-search.b-search--extendable {
			width: 100%;
			height: 100%;

			.b-search__button {
				width: 100%;
				height: 100%;
			}
		}
	}

	&__item--share {
		//margin-top: 45px;

		.b-burger-button--share {
			height: 100%;
			width: 100%;
			position: relative;
		}

		.b-burger-button--share:before {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	&__item--lang,
	&__item--info {
		font-size: 24px;

		a {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
			text-decoration: none;
			color: #fff !important;
		}
	}

	&.is-expand {
		&:after {
			top: 80px;
		}

		// off перекрас
		.l-navigation-panel__item--logo {
			.b-logo {
				.letters {
					path {
						// fill: $color-cardinal;
						fill: $color-white;
						transition: all 0.2s linear;
					}
				}

				// .sign {
				// 	path {
				// 		fill: $color-cardinal;
				// 	}
				// }
			}
		}
	}
}

.menu-adaptive-tools {
	.l-navigation-panel__item {
		height: 80px;
	}
}

// .l-main-menu-cols {
// 	&__col + &__col {
// 		padding-left: 4.4vw;
// 	}

// 	&__copyright {
// 		text-align: left;
// 		padding-left: 16px;
// 	}
// }

@if ($responsive) {
	.l-layout-wrapper--1 {
		.l-layout-wrapper__header.is-fixed {
			width: 100%;
			max-width: $site-width;
			min-width: $site-min-width;
		}
	}

	@include breakpoint(md) {
		.l-layout-wrapper--1 {
			.l-header-top-line__logo {
				display: block;
			}

			.l-header-top-nav__logo {
				display: none;
			}
		}
	}

	@include breakpoint(sm) {
		.l-layout-wrapper--1 {
			.l-page__title {
				margin-bottom: 40px;
			}

			.l-header-top-line {
				margin-bottom: 0;
			}

			.l-header-top-line__title {
				display: none;
			}

			.l-header-top-nav {
				display: none;
			}

			.l-buttons-area__mobile-nav-panel {
				display: block;
			}
		}
	}

	@include breakpoint(sm) {
		.l-layout-wrapper--1 {
			.l-footer__inner {
				//flex-direction: column;
			}

			.l-footer__copyright {
				@include rem(margin-bottom, 10px);
			}

			.l-page {
				flex-direction: column;
			}

			.l-page__sidebar {
				flex-basis: auto;
				width: auto;
			}

			.l-page__nav-breadcrumbs {
				width: 100%;
			}

			.l-page__nav-controls {
				flex-basis: auto;
				width: auto;
			}
		}
	}

	@include breakpoint(xs) {
		.l-layout-wrapper--1 {
			.l-page__nav {
				flex-direction: column;
				align-items: flex-start;
			}

			.l-page__nav-breadcrumbs {
				max-width: 100%;
				@include rem(margin-bottom, 10px);
			}

			.l-page__nav-controls {
				flex-basis: auto;
				width: auto;
			}
		}
	}
}

.body--bg {
	background-color: #1b1a18;

	&:before {
		content: '';
		position: fixed;
		top: 0;
		height: 100vh;
		left: 0;
		right: 0;
		background-size: cover;
		z-index: -2;
	}

	&:after {
		content: '';
		position: fixed;
		top: 0;
		height: 100vh;
		left: 0;
		right: 0;
		background-color: #231f20;
		opacity: 0.91;
		z-index: -1;
	}
}

.body--bg1 {
	// background: url("/images/content/bg-1.jpg");
	// background-size: cover;
	// background-attachment: fixed;
	background-color: #1b1a18;

	&:before {
		content: '';
		position: fixed;
		top: 0;
		height: 100vh;
		left: 0;
		right: 0;
		background: url('/images/content/bg-1.jpg');
		background-size: cover;
		z-index: -1;
	}

	.l-page__title h1 {
		color: #fff;
	}
}

.body--bg-corp {
	&:before {
		background-image: url('/images/content/corp.jpg');
	}
}

.body--bg-int {
	&:before {
		background-image: url('/images/content/int.jpg');
	}
}

.body--bg-animated {
	.l-page__title h1 {
		color: #fff;
	}
}

.animated-bg {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: #231f20;
	z-index: -1;

	&:before {
		content: '';
		position: absolute;
		width: 100vw;
		height: 100vh;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background-image: url('/images/components/slider/images/bg-overlay-part.png');
		background-size: 3px 3px;
		z-index: 1;
	}

	&__svg {
		opacity: 0;
		transition: opacity 0.3s ease 1s;
		min-height: 100%;

		.browser-ie & {
			transition-delay: 0;
		}

		&.is-loaded {
			opacity: 1;
		}
	}
}

// .os-content {
// 	margin-top: -60px;
// }

@media (min-width: 1790px) {
	.l-layout-wrapper--1 {
		.l-page {
			padding: 0 10px 0 90px;
		}
	}
}

@media (max-width: 980px) {
	.l-layout-wrapper--1 {
		.l-page {
			padding-left: 102px;
			padding-right: 22px;
		}
	}
}

@media (max-width: 798px) {
	.l-layout-wrapper--1 {
		padding-top: 80px;

		.l-main-menu-wrapper__panel {
			z-index: -1;
		}

		h1 {
			font-size: 40px;
		}

		h2 {
			font-size: 34px;
		}

		.b-main-menu-item__title {
			font-size: 25px;
		}

		.b-main-menu-item__image-link-title {
			font-size: 16px;
		}

		.l-page {
			padding-left: 16px;
			padding-right: 16px;
		}

		// .b-nav--left.b-nav--vertical-accordeon .b-nav__list-item {
		// 	margin-bottom: 16px;
		// }

		.l-layout-wrapper__navigation {
			width: 100%;
			height: 60px;
		}

		.l-navigation-panel {
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			padding-left: 16px;
			padding-right: 16px;
			border-bottom: 1px solid #ffffff;
		}

		.l-navigation-panel__item--menu {
			margin-bottom: 0;
			width: 50px;
			margin: auto;
			// margin-right: auto;
			// margin-left: 20px !important;
		}

		.l-navigation-panel__item--logo .b-logo {
			position: relative;
			top: auto;
			left: auto;
			transform: none;
		}

		.l-navigation-panel__item {
			height: 60px;
			width: 80px;
		}

		.b-main-menu-item__content {
			margin-left: 20px;
		}

		.b-main-menu-item__content .row [class*="col-"] {
			margin-bottom: 0;
		}

		.b-main-menu-item--sections .b-main-menu-item__link {
			margin-bottom: 10px;
		}

		.b-main-menu-item--projects {
			//display: none;

			.b-main-menu-item__content {
				display: none;
			}
		}

		.b-main-menu-item__image-link img {
			display: none;
		}

		.b-main-menu-item--sections .b-main-menu-item__content .b-main-menu-item__image-link {
			height: auto;
		}

		// .l-main-menu-cols__col {
		// 	flex-basis: 100%;
		// 	max-width: 100%;
		// }

		// .l-main-menu-cols__col + .l-main-menu-cols__col {
		// 	padding-left: 15px;
		// }

		.l-navigation-panel__item--info {
			z-index: 2;
		}

		.l-navigation-panel:after {
			content: none;
		}

		.b-main-menu-item--projects {
			margin-top: 0;
		}

		// .l-main-menu-cols__col:last-child {
		// 	margin-top: -18px;
		// }

		// .l-navigation-panel__item + .l-navigation-panel__item {
		// 	margin-left: 5px;
		// 	margin-right: 5px;
		// }

		.menu-adaptive-tools {
			display: flex;
			flex-direction: column;
			width: 80px;

			&__list {
				position: absolute;
				right: 0;
				top: calc(100% + 1px);
				display: none;
				background: $color-black;

				.l-navigation-panel__item {
					width: 60px;
				}
			}

			&__button {
				width: 50px;
				height: 50px;
				cursor: pointer;
				@include webfont-icon($webfont-icon--tools_5);
				position: relative;
				margin-left: auto;
				margin-right: -10px;

				&:before {
					font-size: 30px;
					color: $color-white;
					margin: auto;
					top: 10px;
					margin: auto;
					bottom: 0;
					position: absolute;
					right: 0;
					left: 0;
				}
			}
		}
	}
}

@include breakpoint(xs) {
	.l-layout-wrapper--1 {
		.b-nav--left.b-nav--vertical-accordeon .b-nav__list-item {
			margin-bottom: 14px;
		}

		.b-main-menu-item__title {
			font-size: 18px;
		}

		.l-main-menu-cols {
			padding: 0 30px;
		}

		.l-main-menu-cols__col {
			position: relative;
			padding-top: 0px;
			padding-bottom: 16px;
		}
	}
}
