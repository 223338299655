$tabs--button-bg: $color-dark-gray;
$tabs--button-bg-active: $color-ironside-gray;
$tabs--button-bg-hover: $color-taupe-gray;
$tabs--button-text-color: $color-white;
$tabs--button-text-color-hover: $color-white;
$tabs--body-bg: $color-white;

.b-tabs {
	&__button {
		@extend %disable-visited;
		@extend %collapse--top;
		display: flex;
		flex-grow: 1;
		flex-shrink: 1;
		justify-content: center;
		align-items: center;
		background-color: $tabs--button-bg;
		@include rem(padding, 5px);
		position: relative;
		text-decoration: none;
		color: $tabs--button-text-color;
		text-align: center;
		height: 100%;
		@include font-size(18px);
		transition: all .2s ease-in-out;

		&:visited {
			color: $tabs--button-text-color;
		}

		&:focus {
			color: $tabs--button-text-color;
			background-color: $tabs--button-bg;

			&:visited {
				color: $tabs--button-text-color;
				background-color: $tabs--button-bg;
			}
		}

		&:hover {
			color: $tabs--button-text-color-hover;
			background-color: $tabs--button-bg-hover;

			&:visited {
				color: $tabs--button-text-color-hover;
				background-color: $tabs--button-bg-hover;
			}
		}

		&.is-expand,
		&.is-expand:focus {
			color: $tabs--button-text-color-hover;
			background-color: $tabs--button-bg-active;

			&:visited {
				color: $tabs--button-text-color-hover;
				background-color: $tabs--button-bg-active;
			}
		}
	}

	&__nav-item {
		border-left: 1px solid $color-white;

		&:first-child {
			border-left: none;
		}
	}

	&__body {
		@extend %collapse--top;
		background-color: $tabs--body-bg;
		width: 100%;
	}

	&__article {
		@extend %collapse--top;
		position: absolute;
		left: -99999px;
		top: -99999px;
		@include rem(padding, 15px 15px 16px 15px);
		border-left: 1px solid $color-celeste;
		border-right: 1px solid $color-celeste;
		border-bottom: 1px solid $color-celeste;

		&.is-expand {
			position: relative;
			left: 0;
			top: 0;
		}
	}
}

.b-tabs--career {
	.b-tabs__article {
		padding-left: 0;
		color: $color-white;

		p {
			color: $color-white;
		}
	}

	.b-tabs__button {
		background: transparent;
		text-align: left;
		font-size: 30px;
		font-weight: 600;
		color: #939597;

		&.is-expand {
			background: transparent;
			color: $color-white;
		}
	}

	.b-tabs__nav-item {
		border-left: 1px solid #ed1846;
		padding-left: 8px;

		&:first-child {
			border-left: none;
		}
	}

	.b-tabs__nav {
		border-bottom: 1px solid #ed1846;
		padding-bottom: 8px;
	}

	.b-tabs__article {
		border-left: none;
		border-right: none;
		border-bottom: none;
	}

	.b-tabs__body {
		background: transparent;
	}
}
